.main-component-img {
    margin-top: auto;
    margin-bottom: auto;
}

.with-header-margin h1 {
    margin-top: 1em;
}

@media only screen and (min-width: 800px) {
    .main-component {
        display: grid;
        min-height: 24em;
        grid-template-columns: repeat(20, 1fr);
        column-gap: 0.5em;
    }
    .main-component-img {
        grid-column: 1/span 10; 
    }
    .main-component-text {
        grid-column: 12/span 9; 
        margin-top: auto;
        margin-bottom: auto;
    }

    .left .main-component-img {
        grid-column: 11/span 10;  
    }
    .left .main-component-text {
        grid-column: 1/span 9;  
        margin-top: -14em;
    }
}