.video p {
    margin-top: 0;
    margin-bottom: 1em;
}

@media only screen and (min-width: 800px) {
    .video iframe {
        height: 24em;
        width: 40em;
    }
}