/* -----------------------
General
1 fr = 1 fractional part of available space
----------------------- */
.App {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto auto;
  column-gap: 0.5em;
  row-gap: 3em;
  padding: 2em;
}

/* -----------------------
Nav
----------------------- */
#burger-nav {
  cursor: pointer;
  margin: auto;
}

.bar1, .bar2 {
  width: 1.6em;
  height: 0.2em;
  background-color: #0C2340;
  transition: 0.4s;
}
.bar2 {
  margin-top: 0.42em;
}
#burger-nav.active .bar1  {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#burger-nav.active .bar2  {
  -webkit-transform: rotate(45deg) translate(-0.45em, -0.45em);
  transform: rotate(45deg) translate(-0.45em, -0.45em);
}

#main-nav {
  display: none;
}
#main-nav.active  {
  display: block;
  text-align: center;
  grid-row: 2;
  grid-column: 1/span 6;
}
#main-nav.active li a {
  text-decoration: none;
  color: #0C2340;
  font-weight: 400;
  line-height: 2em;
}
#main-nav.active li a.selected {
  font-weight: 600; /* semi-bold 600 */
}

/* -----------------------
Logo
----------------------- */
.container-logo {
  grid-column: 3/span 2;
}
.container-logo img {
  height: 6em;
}

/* -----------------------
Content
----------------------- */
/* container inside Menu Components */
.container {
  grid-row: 3;
  grid-column: 1/span 6;
  margin-left: auto;
  margin-right: auto;
}
.nav-active {
  opacity: 0.5;
}


/* -----------------------
Footer
----------------------- */
footer {
  grid-row: 4;
  grid-column: 1/span 6;
}
footer p {
  padding-top: 1.6em;
  text-align: center;
  margin: 0;
  border-top: 0.05em solid #0C2340;
} 

/* -----------------------
Desktop
----------------------- */
@media only screen and (min-width: 800px) {
  .App {
    grid-template-columns: repeat(24, 1fr);
  }
  #burger-nav {
    grid-column: 3;
  }
  #main-nav.active {
    grid-column: 3/span 20;
  }
  #main-nav.active ul {
    display: flex;
    justify-content: center;
  }
  #main-nav.active.desktop ul {
    text-align: center;
  }
  #main-nav.active li {
    margin-right: 2em;
  }
  .container-logo {
    grid-column: 11/span 4;
  }
  .container-logo img {
    height: auto;
  }
  .container {
    grid-row: 3;
    grid-column: 3/span 20;
  }
  footer {
    grid-column: 3/span 20;
  }
}