#about-container .main-component .main-component-text {
    margin-top: 0;
}

#about-container .main-component .main-component-img {
    display: none;
}

#about-container .main-component {
    min-height: 0;
}

@media only screen and (min-width: 800px) {
    #about-container .main-component .main-component-text {
        grid-column: 1/span 20;
    }    
}
