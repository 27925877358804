#map {
    margin-bottom: 1em;
}
#map iframe {
    height: 24em;
    width: 100%;
}

@media only screen and (min-width: 800px) {
    #map iframe {
        height: 24em;
        width: 40em;
    }
}