#impression-container {
    grid-row: 3;
    grid-column: 1/span 6;
}

@media only screen and (min-width: 800px) {
    #impression-container {
        grid-row: 3;
        grid-column: 3/span 20;
        display: grid;
        grid-template-columns: repeat(20, 1fr);
        grid-column-gap: 0.5em;
        column-gap: 0.5em;
        row-gap: 0.5em;
    }
    #impression-container .img-left {
        grid-column: 1/span 10;
    }
    #impression-container .img-right {
        grid-column: 11/span 10;
    }

}